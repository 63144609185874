<template>
  <div>
    <div class="hand_chart">
      <div :id="heatChartId" class="chart-box"></div>
    </div>
    <div class="bars p-4 pt-0 ps-3">
      <div class="py-1">
        <table>
          <tr>
            <td>
              <div class="bar-title">{{ $t("radar_chart.comfort_value") }}：</div>
            </td>
            <td class="w-100 py-5">
              <feel-tip-bar bar-type="comfort_value" :value="comfort_data.comfort_value"></feel-tip-bar>
            </td>
          </tr>
          <tr>
            <td>
              <div class="bar-title">{{ $t("radar_chart.dampness_sensation") }}：</div>
            </td>
            <td class="w-100 py-5">
              <feel-tip-bar bar-type="dampness_sensation" :value="comfort_data.dampness_sensation"></feel-tip-bar>
            </td>
          </tr>
          <tr>
            <td>
              <div class="bar-title">{{ $t("radar_chart.thermal_sensation") }}：</div>
            </td>
            <td class="w-100 py-5">
              <feel-tip-bar bar-type="thermal_sensation" :value="comfort_data.thermal_sensation"></feel-tip-bar>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import {radar1Chart} from "@/utils/chart";
import _ from "lodash";
import FeelTipBar from "@/components/feel-tip-bar.vue";

export default {
  components: {FeelTipBar},
  props: {
    detailForm: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      heatChartId: `heat_chart${+new Date()}`,
      comfort_star: {
        dampness_sensation: "",
        thermal_sensation: "",
        comfort_value: "",
        total: "",
      },
      comfort_data: {
        dampness_sensation: "",
        thermal_sensation: "",
        comfort_value: "",
        total: "",
      },
      comfort_score: {
        dampness_sensation: "",
        thermal_sensation: "",
        comfort_value: "",
        total: "",
      },
    };
  },
  methods: {
    initChart() {
      this.heatFeelChart();
    },
    heatFeelChart() {
      let options = {
        data: [
          this.get_number_default(this.comfort_data.comfort_value),
          this.get_number_default(this.comfort_data.dampness_sensation),
          this.get_number_default(this.comfort_data.thermal_sensation),
          this.get_number_default(this.comfort_data.total),
        ],
        ave: [
          this.get_number_default(this.comfort_score.comfort_value),
          this.get_number_default(this.comfort_score.dampness_sensation),
          this.get_number_default(this.comfort_score.thermal_sensation),
          this.get_number_default(this.comfort_score.total),
        ],
        color: "#ED5E5E",
        name: this.$t("radar_chart.heat"),
        chartDom: document.querySelector(`#${this.heatChartId}`),
      };
      radar1Chart(options, 'mobile');
    },
    number_fixed(data) {
      if (data == "") {
        return data;
      }
      return parseFloat(data.toFixed(2));
    },
    get_number_default(data) {
      if (data == "") {
        return 0;
      }
      return data;
    },
  },
  watch: {
    detailForm: {
      handler(val) {
        if (val) {
          this.comfort_star.dampness_sensation = this.number_fixed(
            _.get(val, "dampness_sensation", "")
          );
          this.comfort_star.thermal_sensation = this.number_fixed(
            _.get(val, "thermal_sensation", "")
          );
          this.comfort_star.comfort_value = this.number_fixed(
            _.get(val, "comfort_value", "")
          );
          this.comfort_star.total = this.number_fixed(_.get(val, "total", ""));

          this.comfort_data.dampness_sensation = this.number_fixed(
            _.get(val, "score_dat.ds_normalized", 0)
          );
          this.comfort_data.thermal_sensation = this.number_fixed(
            _.get(val, "score_dat.ts_normalized", 0)
          );
          this.comfort_data.comfort_value = this.number_fixed(
            _.get(val, "score_dat.cv_normalized", 0)
          );
          this.comfort_data.total = this.number_fixed(
            _.get(val, "score_dat.t_normalized", 0)
          );

          this.comfort_score.dampness_sensation = this.number_fixed(
            _.get(val, "avg_score_dat.ds_normalized", 0)
          );
          this.comfort_score.thermal_sensation = this.number_fixed(
            _.get(val, "avg_score_dat.ts_normalized", 0)
          );
          this.comfort_score.comfort_value = this.number_fixed(
            _.get(val, "avg_score_dat.cv_normalized", 0)
          );
          this.comfort_score.total = this.number_fixed(
            _.get(val, "avg_score_dat.t_normalized", 0)
          );
          this.$nextTick(() => {
            this.initChart();
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>
<style scoped lang="scss">
.bar-title {
  width: max-content;
  margin-top: 1.25rem;
}
.chart-box {
  width: 100vw;
  height: calc(100vw * 0.85);
}
</style>
