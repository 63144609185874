<template>


<div>
  <b-card class="mb-0" no-body>
    <b-card-header>
      <h4>{{ $t('homeTextileProfile') }}</h4>
    </b-card-header>
    <b-card-body style="padding: 0">
      <Baseinfo v-if="detailForm" :detail="detailForm" />
      <div class="position-relative mt-4">
        <div class="table_tab">
          <ul
            class="nav nav-tabs nav-tabs-custom nav-success py-0 px-0 d-flex justify-content-around"
            role="tablist"
          >
            <li class="nav-item" v-for="(tab, i) in tabInfo" :key="i">
              <a
                :class="[
                  'nav-link All py-3 px-0 position-relative cursor',
                  tab.type === current_tab && 'active',
                ]"
                @click="handlerToggleTab(tab)"
              >{{ tab.title }}</a
              >
            </li>
          </ul>
        </div>
        <div class="pt-4">
          <hand v-if="detailForm && current_tab === 'hand'" :detail-form="detailForm" type="homeTextile"></hand>
          <skin v-if="detailForm && current_tab === 'skin'" :detail-form="detailForm" type="homeTextile"></skin>
          <heat v-if="detailForm && current_tab === 'heat'" :detail-form="detailForm" type="homeTextile"></heat>
        </div>
      </div>
    </b-card-body>
  </b-card>


</div>



</template>

<script>

import { setLanguageByBrowser, getLanguage } from "@/i18n";
import {
  getPublicHomeTextileDetail,
} from "@/service";
import Baseinfo from "@/views/mobile/garment/components/baseinfo.vue";
import Hand from "@/views/mobile/garment/components/hand.vue";
import Skin from "@/views/mobile/garment/components/skin.vue";
import Heat from "@/views/mobile/garment/components/heat.vue";

export default {
  components: {
    // DetailTopBaseCard,
    Baseinfo,
    Hand,
    Skin,
    Heat,
  },
  data() {
    return {
      /* 面包屑 */
      PageHeaderT: this.$t("garment.detail.title"),
      PageHeaderItems: [
        {
          text: this.$t("garment.title"),
          active: true,
          href: "/garment",
        },
        {
          text: this.$t("garment.detail.title"),
          active: true,
        },
      ],
      showType: this.$route.query.type || "",
      /* tab 信息 */
      tabInfo: [
        {
          type: 'hand',
          title: this.$t('radar_chart.feel')
        },
        {
          type: 'skin',
          title: this.$t('radar_chart.skin')
        },
        {
          type: 'heat',
          title: this.$t('radar_chart.heat')
        },
      ],
      detailForm: null,
      searchList: null,
      current_tab: 'hand',
      /* 提交数据表单 */
      submitForm: {},
      /* 分页 */
      tab_info_length: 0,
      page: 1,
      perPage: 10,
      prePageOption: [10, 20, 30, 40, 50],
      pages: [],
      alertInfo: {
        variant: "danger",
        content: "",
        icon: "ri-error-warning-line",
      },
      formValidataAlert: false,
      is_public: [],
      sankey: {},
    };
  },
  watch: {

  },
  computed: {

  },
  beforeCreate() {
    setLanguageByBrowser()
    this.$i18n.locale = getLanguage()
  },
  mounted() {
    this.initDetail()
  },
  methods: {



    initDetail() {
      // 从path中获取ID
      const id = this.$route.params.id;
      getPublicHomeTextileDetail(id)
        .then((res) => {
          if (res && res.payload && res.payload.data) {
            this.detailForm = res.payload.data;
            this.searchList = res.payload.search_list;
            if (res.payload.data && res.payload.data.is_public) {
              this.is_public = [this.$t("base.is_public")];
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /* 切换tab展示内容 */
    handlerToggleTab(item) {
      if (this.current_tab === item.type) {
        return;
      }
      this.current_tab = item.type;
    },
  },
};
</script>

<style lang="scss">
.table-responsive {
  min-height: 60vh;
  max-height: 60vh;
}

.mask_model {
  width: 100vw;
  height: 100vh;
  background-color: rgba(47, 79, 79, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
</style>
